<template>
  <b-form>
    <div class="grid grid-cols-1 md:grid-cols-12">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-12">
            <input-required
              v-model="value.Name"
              :rules="{ required: true,max:50}"
              max="50"
              :label="$t('sectionPrescriber.personalData.name')"
            />
          </div>
        </div>
      </div>

      <div class="col-span-8 mb-3">
        <div class="flex flex-col items-start gap-2">
          <span class="text__color font-bold required">Tipo CR *</span>
          <div class="flex">
            <div class="flex items-center">
              <radioButton :options="options" label="Tipo CR" :rules="{ required: true}" v-model="value.TipoCr" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-2">
            <input-required
              v-model="value.UfCr"
              :label="$t('sectionPrescriber.personalData.ufCr')"
              max="2"
              :rules="{ required: true,min: 2 }"
              mask="SS"
              placeholder="UF"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-required
              v-model="value.NumeroCR"
              :label="$t('sectionPrescriber.personalData.crNumber')"
              :rules="{ required: true,max:10 }"
              max="10"
              mask="XXXXXXXXXX"
              placeholder="Numero"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="value.DataNascimento"
              :label="$t('sectionPrescriber.personalData.birthDate')"
              mask="##/##/####"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="value.CpfCnpj"
              :label="$t('sectionPrescriber.personalData.document')"
              :rules="{ min: 14,max:18 ,cpfCnpj:true}"
              max="18"
              :mask="['###.###.###-##', '##.###.###/####-##']"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 xl:col-span-3">
            <input-default
              v-model="value.endereco[0].CEP"
              :label="$t('sectionPrescriber.addressData.zipCode')"
              mask="#####-###"
              @cep="$emit('cep',$event)"
              :rules="{ min:9,max:9 }"
              max="9"
              :hasIcon="true"
            />
          </div>
          <div class="col-span-12 xl:col-span-7">
            <input-default
              v-model="value.endereco[0].Endereco"
              :rules="{ max:50}"
              :label="$t('sectionPrescriber.addressData.road')"
              max="50"
            />
          </div>
          <div class="col-span-12 lg:col-span-2">
            <input-default
              v-model="value.endereco[0].Numero"
              :rules="{max:7 }"
              :label="$t('sectionPrescriber.addressData.number')"
              placeholder="Nº"
              max="7"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <Dropdown
              :Items="dropdownItemsEstado"
              v-model="value.endereco[0].EstadoId"
              :valueDefault="$t('sectionPrescriber.addressData.state')"
              :label="$t('sectionPrescriber.addressData.state')"
              @ItemSelectId="DropdownSelected($event, 'estado')"
            >
            </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <Dropdown
              :Items="dropdownItemsCidade"
              v-model="value.endereco[0].CidadeId"
              :valueDefault="$t('sectionPrescriber.addressData.city')"
              :label="$t('sectionPrescriber.addressData.city')"
              @ItemSelectId="DropdownSelected($event, 'cidade')"
            >
            </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <Dropdown
              :Items="dropdownItemsBairro"
              v-model="value.endereco[0].BairroId"
              :valueDefault="$t('sectionPrescriber.addressData.district')"
              :label="$t('sectionPrescriber.addressData.district')"
              @ItemSelectId="DropdownSelected($event, 'bairro')"
            >
            </Dropdown>
          </div>
           <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="value.endereco[0].Complemento"
                max="20"
                :rules="{max:20 }"
                :label="$t('sectionProviders.addressData.complemento')"
              />
            </div>
        </div>
      </div>
      <div class="col-span-12"></div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-2">
            <input-default
              v-model="value.Telefone[0].DDD"
              :label="$t('sectionPrescriber.contactData.ddd1')"
              placeholder="(99)"
              max="4"
              mask="(##)"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="value.Telefone[0].Telefone"
              :label="$t('sectionPrescriber.contactData.phone')"
              placeholder="9999-9999"
              mask="####-####"
              max="9"
            />
          </div>
          <div class="col-span-12 lg:col-span-2">
            <input-default
              v-model="value.Telefone[1].DDD"
              :label="$t('sectionPrescriber.contactData.ddd2')"
              placeholder="(99)"
              max="5"
              mask="(##)"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="value.Telefone[1].Telefone"
              :label="$t('sectionPrescriber.contactData.cellPhone')"
              placeholder="9 9999-9999"
              mask="# ####-####"
              max="11"
            />
          </div>
        </div>
      </div>
      <div class="col-span-8">
        <input-default
          v-model="value.Secretaria"
          :label="$t('sectionPrescriber.complementaryInfo.secretary')"
          max="255"
        />
      </div>
      <div class="col-span-8">
        <input-default
          v-model="value.NomeRotulo"
          max="50"
          :label="$t('sectionPrescriber.complementaryInfo.labelName')"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12">
            <div class="flex items-center justify-between">
              <span class="text__color text-xl font-bold">{{
                $t("sectionPrescriber.especialidade")
              }}</span>
            </div>
          </div>
          <div class="col-span-12 mb-3">
            <selectTag
              :descricao="$t('sectionPrescriber.especialidade')"
              :items="seletorEspecialidades"
              v-model="value.Especialidade"
              :remove="RemoverEspecialidade"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex items-center justify-start">
      <CheckboxDefault label="Ativo" v-model="value.Ativo" />
    </div>
  </b-form>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import radioButton from "@core/components/commons/radioButton/radioButton.vue";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";
import selectTag from "@core/components/commons/selectTag/selectTagDropdownEdit";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    CheckboxDefault,
    radioButton,
    Dropdown,
    selectTag,
  },
  props: {
    required,
    value: {
      type: Object,
      require: true,
    },
    dropdownItemsEstado: {
      type: Array,
      required:true
    },
  },
  data() {
    return {
      seletorEspecialidades: [],
      dropdownItemsBairro: [],
      dropdownItemsCidade: [],
      options: [
        { text: "CRM", value: "0" },
        { text: "CRMV", value: "1" },
        { text: "CRO", value: "2" },
        { text: "OUTRO", value: "3" },
      ],
    };
  },
  created() {
    this.ObterSeletorEspecialidades();
    this.ObterSeletorEstado();
  },
  methods: {
    ObterSeletorEspecialidades() {
      this.$http({
        url: `/especialidade/obter-seletor-ObterSeletorEspecialidade`,
        method: "GET",
      }).then((res) => {
        this.seletorEspecialidades = res.data;
      });
    },
    ObterSeletorBairro(cidadeId) {
      this.$http({
        url: `/bairro/obter-seletor-bairro`,
        params: { idCidade: `${cidadeId}` },

        method: "GET",
      }).then((res) => {
        this.dropdownItemsBairro = res.data;
      });
    },
   
    ObterSeletorCidade(estadoId) {
      this.$http({
        url: `/cidade/obter-seletor-cidades-por-estado/${estadoId}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCidade = res.data;
      });
    },
    DropdownSelected(item, tipo) {
      if (tipo == "estado") {
        this.ObterSeletorCidade(item);
      } else if (tipo == "cidade") {
        this.ObterSeletorBairro(item);
      }
    },
    RemoverEspecialidade (id) {
      this.$http({
        url: `/prescritor/RemoveEspecialidadePrescritor`,
        params: {  PrescritorId: this.$router.currentRoute.params.id, EspecialidadeId: id  },
          method: "POST",
        })
          .then(() => {
            this.modalEspecialidadeIsOpen = true;
          }).catch(() =>  console.log(id))
    },
  },
};
</script>

<style scoped lang="scss">
.text__color {
  color: #cf0209;
}
</style>
