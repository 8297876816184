<template>
<ValidationObserver ref="observer" v-slot="{ validate  }">
  <b-form @submit.stop.prevent="validate().then(Edit)">
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralPrescritorInformation class="mt-4"  
                                        @cep="RecuperarDadosCEP($event,0)"
                                        v-model="viewModel"
                                        :dropdownItemsEstado="dropdownItemsEstado" />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationPrescritor class="mt-4" 
                                              @cep="RecuperarDadosCEP($event,1)"
                                               v-model="viewModel"
                                               :dropdownItemsEstado="dropdownItemsEstado" />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
    </div>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        type="submit"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="redirectUrl"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextEdit')"
    />
    <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
    />

    <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
    />
  </div>
</b-form>
</ValidationObserver>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPrescritorInformation from "./GeneralPrescritorInformation";
import SupplementaryInformationPrescritor from "./SupplementaryInformationPrescritor";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ManagePrescritorEdit",
  components: {
    BTabs,
    BTab,
    BForm,
    GeneralPrescritorInformation,
    SupplementaryInformationPrescritor,
    ValidationObserver,
    Button,
    Modal,
  },
  data() {
    return {
      modalEspecialidadeIsOpen:false,
      modalIsOpen: false,
      modalError: false,
      modalErroSalvar: false,
      modalErrorMessage: null,
      cep:[null,null],
      dropdownItemsEstado:[],
      viewModel: {
        id: null,
        Name: null,
        TipoCr: null,
        CpfCnpj: null,
        DataNascimento: null,
        Ativo: null,
        NumeroCR: null,
        UfCr:null,
        Secretaria: null,
        NomeRotulo: null,
        Email: null,
        VisitadorId: null,
        ObservacaoVendas: null,
        RegistroMapa: null,
        Proximidade: null,
        CEDH: false,
        endereco: [
          {
            CEP: null,
            Endereco: null,
            BairroId: null,
            CidadeId: null,
            EstadoId: null,
            Numero: null,
            IsResidencial: false,
            Complemento:null,
          },
          {
            CEP: null,
            Endereco: null,
            BairroId: null,
            CidadeId: null,
            EstadoId: null,
            Numero: null,
            IsResidencial: false,
            Complemento:null,
          },
        ],
        Telefone: [
          {
            DDD: null,
            Telefone: null,
            IsResidencial: false,
          },
          {
            DDD: null,
            Telefone: null,
            IsResidencial: false,
          },
          {
            DDD: null,
            Telefone: null,
            IsResidencial: true,
          },
        ],
        Especialidade: [],
      },
    };
  },
  created() {
    this.RecuperarUser(this.$router.currentRoute.params.id);
    this.ObterSeletorEstado();
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/prescritor");
    },
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        this.modalEspecialidadeIsOpen = false;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    ObterSeletorEstado() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsEstado = res.data;
      });
    },
    RecuperarUser(id) {
      this.$http({
        url: `/prescritor/obter/${id}`,
        method: "Get",
      }).then((response) => {
        (this.viewModel.Name = response.data.nome),
          (this.viewModel.id = response.data.id),
          (this.viewModel.TipoCr = response.data.tipoCr),
          (this.viewModel.CpfCnpj = response.data.cpfCnpj),
          (this.viewModel.DataNascimento = this.dataFormatada(
            response.data.dtAniversario
          )),
          (this.viewModel.Ativo = response.data.snAtivo),
          (this.viewModel.NumeroCR = response.data.nrCr),
          (this.viewModel.UfCr = response.data.siglaUFCR),
          (this.viewModel.Secretaria = response.data.nmSecretaria),
          (this.viewModel.NomeRotulo = response.data.nomeRotulo),
          (this.viewModel.Especialidade = response.data.prescritorEspecialidade.map(
            (x) => { 
              return {id: x.especialidadeId, descricao: x.especialidade}
            }
          )),
        (this.viewModel.Email = response.data.email),
          (this.viewModel.VisitadorId = response.data.visitadorId),
          (this.viewModel.ObservacaoVendas = response.data.observacaoVenda),
          (this.viewModel.RegistroMapa = response.data.numRegMapa),
          (this.viewModel.Proximidade = response.data.proximidade),
          (this.viewModel.CEDH = response.data.cedh);
        var telefone = response.data.telefones?.filter(
          (item) => item.numero?.length <= 8 && !item.isResidencial
        );
        this.viewModel.Telefone[0] = {
          DDD: telefone[0]?.ddd,
          Telefone: telefone[0]?.numero,
          IsResidencial: telefone[0]?.isResidencial,
        };

        var celular = response.data.telefones?.filter(
          (item) => item.numero?.length >= 9 && !item.isResidencial
        );
        this.viewModel.Telefone[1] = {
          DDD: celular[0]?.ddd,
          Telefone: celular[0]?.numero,
          IsResidencial: celular[0]?.isResidencial,
        };
        var telefoneResidencial = response.data.telefones?.filter(
          (item) => item.numero?.length >= 8 && item.isResidencial
        );
        this.viewModel.Telefone[2] = {
          DDD: telefoneResidencial[0]?.ddd,
          Telefone: telefoneResidencial[0]?.numero,
          IsResidencial: telefoneResidencial[0]?.isResidencial,
        };
        //Endereço
        var endereco = response.data.enderecos?.filter(
          (item) => !item.isResidencial
        );
        (this.viewModel.endereco[0] = {
          CEP: endereco[0].cep,
          Endereco: endereco[0].rua,
          BairroId: endereco[0].bairroId,
          CidadeId: endereco[0].cidadeId,
          EstadoId: endereco[0].estadoId,
          Numero: endereco[0].numero,
          Complemento: endereco[0].complemento,
          IsResidencial: endereco[0].isResidencial,
        });
        var enderecoResidencial = response.data.enderecos?.filter(
          (item) => item.isResidencial
        );
        this.viewModel.endereco[1] = {
          CEP: enderecoResidencial[0].cep,
          Endereco: enderecoResidencial[0].rua,
          BairroId: enderecoResidencial[0].bairroId,
          CidadeId: enderecoResidencial[0].cidadeId,
          EstadoId: enderecoResidencial[0].estadoId,
          Numero: enderecoResidencial[0].numero,
          Complemento: enderecoResidencial[0].complemento,
          IsResidencial: enderecoResidencial[0].isResidencial,
        };
      });
    },
   async Edit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.modalError = true;
        return
      }
        this.RemoverMascaras();
        this.$http({
          url: `/prescritor/editar`,
          params: { PrescritorId: this.viewModel.id },
          data: this.viewModel,
          method: "POST",
        })
          .then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push({ name: "prescritor" }), 3000);
          })
          .catch(() => (this.modalErroSalvar = true));
    },
    RemoverMascaras () {
      this.viewModel.Telefone = this.viewModel.Telefone?.filter((item) => item.DDD != null && item.DDD != "" && item.Telefone != null &&item.Telefone != "" );
      if (this.viewModel.DataNascimento == "") this.viewModel.DataNascimento == null;
      if (this.viewModel.Telefone?.length == 0) this.viewModel.Telefone = null;
      if (this.viewModel.endereco.CEP?.length == 0) this.viewModel.endereco.CEP = null;
      if (this.viewModel.Especialidade?.length == 0) this.viewModel.Especialidade = null;

      for (let index = 0; index < this.viewModel.Telefone?.length; index++) {
        this.viewModel.Telefone[index].DDD = this.viewModel.Telefone[
          index
        ].DDD?.replace(/[^0-9,]*/g, "");
        this.viewModel.Telefone[index].Telefone = this.viewModel.Telefone[
          index
        ].Telefone?.replace(/[^0-9,]*/g, "");
      }
      for (let index = 0; index < this.viewModel.endereco?.length; index++) {
        this.viewModel.endereco[index].CEP = this.viewModel.endereco[
          index
        ].CEP?.replace(/[^0-9,]*/g, "");
      }
      this.viewModel.CpfCnpj = this.viewModel.CpfCnpj?.replace(/[^0-9,]*/g, "");
    },
    dataFormatada (aniversario) {
      if (aniversario == null || aniversario == "")
        return null;

      var data = aniversario?.split("-");
      return `${data[2]?.substr(0, 2)}/${data[1]}/${data[0]}`;
    },
    RecuperarDadosCEP (value, tipo) {
      if (value.length == 9 && tipo == 0  && this.cep[0] != value) {
        this.cep[0] = value;
          this.$http({
            url: `/Endereco/cep`,
            params: { CEP: value },
            method: "POST"
          }).then((res) => {
            if (res.data.ufCadastrado) {
              this.dropdownItemsEstado?.push({ id: res.data.ufId, descricao: res.data.uf })
            }
            this.$set(this.viewModel.endereco, 0, {
                CEP:value,
                Endereco : res.data.logradouro != '' ? res.data.logradouro : this.viewModel.endereco[0].Endereco,
                EstadoId : res.data.ufId != '' ? res.data.ufId : this.viewModel.endereco[0].EstadoId,
                CidadeId : res.data.cidadeId != '' ? res.data.cidadeId : this.viewModel.endereco[0].CidadeId,
                BairroId: res.data.bairroId != '' ? res.data.bairroId : this.viewModel.endereco[0].BairroId,
                Numero: this.viewModel.endereco[0].Numero,
                IsResidencial: this.viewModel.endereco[0].IsResidencial,
                Complemento:this.viewModel.endereco[0].Complemento,
            })
          })
      }
      else if (value.length == 9 && tipo == 1  && this.cep[1] != value) {
        this.cep[1] = value;
          this.$http({
            url: `/Endereco/cep`,
            params: { CEP: value },
            method: "POST"
          }).then((res) => {
            if (res.data.ufCadastrado) {
              this.dropdownItemsEstado?.push({ id: res.data.ufId, descricao: res.data.uf })
            }
            this.$set(this.viewModel.endereco, 1, {
                      CEP: value,
                      Endereco : res.data.logradouro != '' ? res.data.logradouro : this.viewModel.endereco[1].Endereco,
                      EstadoId : res.data.ufId != '' ? res.data.ufId : this.viewModel.endereco[1].EstadoId,
                      CidadeId : res.data.cidadeId != '' ? res.data.cidadeId : this.viewModel.endereco[1].CidadeId,
                      BairroId: res.data.bairroId != '' ? res.data.bairroId : this.viewModel.endereco[1].BairroId,
                      Numero: this.viewModel.endereco[1].Numero,
                      IsResidencial: this.viewModel.endereco[1].IsResidencial,
                      Complemento: this.viewModel.endereco[1].Complemento,
            });
          })
        }
    }
  },
};
</script>

<style></style>
