<template>
    <div>
    <b-form-group class="select_tag">
      <b-form-tags id="tags-with-dropdown" class="mb-2">
          <template>
          <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
            <template #button-content>
              {{ descricao }}
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label="Search tags"
                label-for="tag-search-input"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                 ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button
              v-for="option in availableOptions"
              :key="option.id || option"
              @click="onOptionClick(option)"
            >
              {{ option.descricao || option }}
            </b-dropdown-item-button>
            <b-dropdown-text v-if="availableOptions.length === 0">
              There are no tags available to select
            </b-dropdown-text>
          </b-dropdown>
          <ul  class="list-inline d-inline-block mb-2 text-sm text-white rounded">
            <li v-for="tag in value"  :key="tag" class="list-inline-item">
              <b-form-tag
                @remove="Remover(tag)"
                :title="tag"
                variant="info"
              >
                 {{tag.descricao}}
              </b-form-tag>
            </li>
          </ul>
        </template>
      </b-form-tags>
    </b-form-group>
  </div>
</template>

<script>

export default {
  name: "selectTagDropdownEdit",
  data () {
    return {
      search: '',
    }
  },
  props: {
    descricao: {
      type: String,
      required: false,
      default: "Select"
    },
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    }
  },
  computed: {
    criteria () {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions () {
      const criteria = this.search.trim().toLowerCase()
      // Filter out already PrescritorEspecialidade options
      const options = this.items.filter(opt => (this.value.indexOf(opt.id) === -1))

      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => {
          if (opt?.descricao != null) {
            return opt.descricao.toLowerCase().indexOf(criteria) > -1
          } else {
            return opt.toLowerCase().indexOf(criteria) > -1
          }
        });
      }
      // Show all options available
      return options
    },
  },
  // watch: {
  //   value: {
  //     handler (newValue) {
  //       var lista = [];
  //       for (let index = 0; index <= this.items.length; index++) {
  //         for (let index2 = 0; index2 < newValue.length; index2++) {
  //           if (this.items[index]?.descricao == newValue[index2] || this.items[index] == newValue[index2])
  //           lista.push(this.items[index])
  //         }
  //       }
  //       if (newValue.length == 0)
  //         lista = null;

  //       this.$emit("input",lista);
  //     }
  //   },
  // },
  methods: {
    onOptionClick (option) {
      const existe = this.value.filter(x => x.id == option.id)
      if(existe.length == 0)
        this.value.push(option)
      
      this.$emit("input",this.value);
      this.search = ''
    },
    Remover (tag) {
      this.value.splice(tag,1)
      this.$emit("input",this.value);

      this.remove(tag.id);
    }
  }
}

</script>

<style lang="scss">
  .select_tag{
    background-color:#efefef!important;
    border: none;
    border-color: #efefef!important;
    
    #tags-with-dropdown,.dropdown-toggle{
      background-color: #efefef!important;
      color: black!important;
      border: none;
      
      .dropdown  .dropdown-menu{
         background-color: #fff!important;
         background-clip: padding-box;
         text-align: left;
         border: 1px solid rgba(0, 0, 0, 0.15);
         border-radius: 0.25rem;

         .dropdown-item{
          box-shadow: none;
          border: none;
          border-radius: 0 !important;
          display: flex;
          justify-content: flex-start;
          text-decoration: none;
          white-space: nowrap;
          padding: 0.25rem 1.5rem;
          width: 100%;
          font-weight: 400;
              clear: both;
         }
      }
    }
    .dropdown-toggle,
    .dropdown-toggle:hover,
    .dropdown-toggle:active,
    .dropdown-toggle:focus,
    .dropdown-toggle:active{
      background-color:#efefef!important;
      color: black!important;
      border: none;
      box-shadow: none;
      border-radius:0!important;
    }
    ul li{
      margin-bottom: 0.5rem;
      .badge {
        background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
      }
      span{
        width: 6rem;
        height: 1.6rem;
        text-align: start;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
        font-family: inherit;
        color: #fff;
        font-weight: 500;
      }
    }
}

</style>